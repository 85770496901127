// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyATIVDZczAyx_sI2yLoBdCpTNmCJBiLQ6E",
    authDomain: "just-like-home-6df9f.firebaseapp.com",
    projectId: "just-like-home-6df9f",
    storageBucket: "just-like-home-6df9f.appspot.com",
    messagingSenderId: "81404854996",
    appId: "1:81404854996:web:40c26d6ed2b78244127141",
    measurementId: "G-VD5MQF8ETH"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Storage
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };