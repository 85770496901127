// LandingPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase'; // Import the Firebase auth instance
import './LandingPage.css';
import Profile from '../../assets/icons/user.png';

function LandingPage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const motto = "Nourishing Nostalgia";
  const mottoSpans = motto.split("").map((char, index) => (
    <span key={index} style={{ '--i': index }}>{char}</span>
  ));

  useEffect(() => {
    // Set up an authentication state observer and get user data
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      setUser(null); // Reset user state after logout
    });
  };

  const handleGetStarted = () => {
    navigate('/enter-location', { state: { name: user?.displayName || '' } });
  };

  const openProfile = () => {
    navigate('/user-profile');
  };

  return (
    <div className="landing-page">
      <div className="login-header">
        {user ? (
          <>
          <button className="login-button" onClick={handleLogout}>Logout</button>
          <img src={Profile} onClick={openProfile} alt="Profile Icon" className="profile-icon" />
          </>
        ) : (
          <button className="login-button" onClick={handleLogin}>Login</button>
        )}
        </div>
      <div className="title-container">
        <img className='title-logo' src="/logo.png" alt="logo" />
        <h1 className="title">Servillia</h1>
        <h2 className="motto">{mottoSpans}</h2>
      </div>
      <div className="get-started-container">
        <button className="get-started-button" onClick={handleGetStarted}>
          Get Started
        </button>
      </div>
      <hr className="section-divider"/>
      <div className="info-section">
        <p>
          Servillia is your gateway to discovering local businesses, services, and experiences that remind you of home. Whether you're an immigrant looking for nostalgic flavors or a local business wanting to connect with a new audience, Servillia bridges that gap.
        </p>
      </div>
      <div className="benefit-section">
        <div className="card-container">
          <div className="benefit-card">
            <h3>For Users</h3>
            <p>
              Discover local services, connect with businesses that remind you of home, and enjoy authentic experiences in your new city.
            </p>
          </div>
          <div className="benefit-card">
            <h3>For Service Providers</h3>
            <p>
              Grow your business by tapping into an audience of immigrants and locals who value authenticity. Showcase your unique services and reach more customers.
            </p>
          </div>
        </div>
      </div>
      <div className="cta-section">
        <div className="cta-user">
          <h3>Looking for Services?</h3>
          <button className='get-started-button' onClick={handleGetStarted}>Get Started</button>
        </div>
        <div className="cta-provider">
          <h3>Are You a Service Provider?</h3>
          <button className='service-provider-button' onClick={() => navigate('/become-service-provider')}>List Your Service</button>
        </div>
      </div>

    </div>
  );
}

export default LandingPage;
