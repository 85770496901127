import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Maintenance from '../../Maintenance';

function AustraliaPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const name = location.state?.name || '';
  const city = location.state?.city || 'Winnipeg'; // Winnipeg by default for now

  return (
    <div>
      <Maintenance />
    </div>
  );
}

export default AustraliaPage;
