import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth,db } from './firebase'; // Import the auth object from firebase.js

// Function to sign up a new user
export const signUp = async (email, password, firstName, lastName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Update the user's profile with their first name and last name
    await updateProfile(user, {
      displayName: `${firstName}`
    });

    // Add user data to Firestore 'users' collection
    await setDoc(doc(db, 'users', user.uid), {
      firstName: firstName,
      lastName: lastName,
      email: user.email,
      favorites: [], // Initialize with empty favorites array
      createdAt: new Date(),
    });

    // Send a verification email
    await sendEmailVerification(user);

    console.log('Verification email sent.');
    console.log('User signed up and added to Firestore!');
    return user;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Function to log in an existing user
export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Function to log out the user
export const logout = async () => {
  try {
    await signOut(auth);
    console.log('User signed out.');
  } catch (error) {
    throw new Error(error.message);
  }
};

// Function to update the user's profile
export const updateUserProfile = async (user, profileData) => {
  try {
    await updateProfile(user, profileData);
    console.log('User profile updated.');
  } catch (error) {
    throw new Error(error.message);
  }
};