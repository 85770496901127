// ServiceDetailPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import './ServiceDetailPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

const ServiceDetailPage = () => {
  const { serviceId } = useParams();
  const [serviceDetails, setServiceDetails] = useState(null);
  const [items, setItems] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [businessImages, setBusinessImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServiceDetails = async () => {
      const docRef = doc(db, 'services', serviceId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const serviceData = docSnap.data();
        setServiceDetails(serviceData);
        setImageUrl(serviceData.banner);
        setBusinessImages(serviceData.businessImages || [serviceData.banner,serviceData.banner,serviceData.banner,serviceData.banner,serviceData.banner]);

        let subcollectionName = '';

        if (serviceData.category === 'food') {
          subcollectionName = 'menu';
        } else if (serviceData.category === 'hair' || serviceData.category === 'other') {
          subcollectionName = 'services';
        }

        // Fetch subcollection if it exists
        if (subcollectionName) {
          try {
            const itemsRef = collection(db, 'services', serviceId, subcollectionName);
            const itemsSnap = await getDocs(itemsRef);
            const itemsList = itemsSnap.docs.map((doc) => doc.data());
            setItems(itemsList);
          } catch (error) {
            console.error('Error fetching subcollection:', error);
          }
        }
      } else {
        console.log('No such document!');
      }
    };

    fetchServiceDetails();
  }, [serviceId]);



  // const handleOrderClick = (platform, message) => {
  //   if (platform.type === 'website') {
  //     window.open(`${platform.url}`, '_blank');
  //   } else if (platform.type === 'whatsapp') {
  //     const whatsappMessage = encodeURIComponent(message);
  //     window.open(`https://wa.me/${platform.username}?text=${whatsappMessage}`, '_blank');
  //   } else {
  //     if (navigator.clipboard && navigator.clipboard.writeText) {
  //       navigator.clipboard.writeText(message)
  //         .then(() => {
  //           if (platform.type === 'snapchat') {
  //             window.open(`https://www.snapchat.com/add/${platform.username}`, '_blank');
  //           } else if (platform.type === 'instagram') {
  //             window.open(`https://www.instagram.com/${platform.username}`, '_blank');
  //           }
  //           alert('Message copied to clipboard! Now paste it to order.');
  //         })
  //         .catch(err => {
  //           console.error('Failed to copy: ', err);
  //         });
  //     } else {
  //       alert(`Copy the following message manually: ${message}`);
  //       if (platform.type === 'snapchat') {
  //         window.open(`https://www.snapchat.com/add/${platform.username}`, '_blank');
  //       } else if (platform.type === 'instagram') {
  //         window.open(`https://www.instagram.com/${platform.username}`, '_blank');
  //       } else if (platform.type === 'whatsapp') {
  //         const whatsappMessage = encodeURIComponent(message);
  //         window.open(`https://wa.me/${platform.username}?text=${whatsappMessage}`, '_blank');
  //       }
  //     }
  //   }
  // };

  const handleOrderClick = (item) => {
    navigate(`/order`, { state: { serviceDetails, item } });
  };

  const renderItems = () => {
    if (serviceDetails.category === 'food') {
      return (
        <div className="menu">        
          <h2>Menu</h2>
          {items.map((item, index) => (
            <div key={index} className="item-card" onClick={() => handleOrderClick(item)}>
              <img src={item.imageUrl} alt={item.name} className='item-image'  />
              <div className="item-details">
                <h3>{item.name}</h3>
                <p>{item.description}</p>
                <span className="item-price">${item.price}</span>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (serviceDetails.category === 'hair' || serviceDetails.category === 'other') {
      return (
        <div className="service-detail">
          <h2>Services</h2>
          {items.map((item, index) => (
            <div key={index} className="item-card" onClick={() => handleOrderClick(item)}>
              <img src={item.imageUrl} alt={item.name} className='item-image' />
              <div className="item-details">
                <h3>{item.name}</h3>
                <p>Description here</p>
                <span className="item-price">${item.price}</span>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (serviceDetails.category === 'local') {
      return (
        <div className="local-business">
          <div className="business-actions">
            <button className="visit-button" onClick={() => window.open(serviceDetails.website, '_blank')}>
              Visit Website
            </button>
            <button className="location-button" onClick={() => window.open(`https://maps.google.com/?q=${serviceDetails.location}`, '_blank')}>
              View Location
            </button>
          </div>
        </div>
      );
    }
  };

  if (!serviceDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className='service-banner-container'>
        {imageUrl && <img src={imageUrl} alt={serviceDetails.name} className="service-banner" />}
      </div>
      <div className="service-detail-page">
        {/* Back Icon
        <FontAwesomeIcon 
            icon={faArrowLeft} 
            className="back-icon" 
            onClick={() => navigate(-1)} 
        /> */}
        <h1>{serviceDetails.name}</h1>
        {renderItems()}
        
        {/* Business Image Gallery Section */}
        <div className="about-section">
              <h2>About {serviceDetails.name}</h2>
              <p>{serviceDetails.longDescription}</p>
              
             {/* Business Image Carousel */}
             <div className="carousel-container">
             <Carousel
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                stopOnHover={true}
                showArrows={true}
                showStatus={false}
              >
                {businessImages.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Business Image ${index + 1}`} className="carousel-image" />
                    <button
                      className="carousel-button"
                      onClick={() => handleOrderClick(serviceDetails.name)}
                    >
                      Order Now
                    </button>
                  </div>
                ))}
              </Carousel>
              </div>
          </div>
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    </div>
  );
};

export default ServiceDetailPage;
