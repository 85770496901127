// BecomeServiceProvider.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom'; 
import './BecomeServiceProvider.css'; // They have the same look

const ServiceSubmit = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    businessName: '',
    category: '',
    otherCategory: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      email: formData.email,
      businessName: formData.businessName,
      category: formData.category === 'Other' ? formData.otherCategory : formData.category,
    };

    emailjs.send('service_moljiqm', 'template_pch9mg8', templateParams, 'op_sPUaDGFLLXs5Ei')
      .then((response) => {
        alert('Request submitted successfully!');
        navigate(-1); // Navigate back to the previous page
      })
      .catch((error) => {
        console.error('Failed to send request:', error);
      });
  };

  return (
    <div className="service-provider-form">
      <h1>Submit Service Here</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
        <label>
          Business Name:
          <input type="text" name="businessName" value={formData.businessName} onChange={handleChange} required />
        </label>
        <label>
          Category:
          <select name="category" value={formData.category} onChange={handleChange} required>
            <option value="">Select a category</option>
            <option value="Food">Food</option>
            <option value="Hair">Hair</option>
            <option value="Local Businesses">Local Businesses</option>
            <option value="Other">Other</option>
          </select>
        </label>
        {formData.category === 'Other' && (
          <label>
            Please specify:
            <input type="text" name="otherCategory" value={formData.otherCategory} onChange={handleChange} required />
          </label>
        )}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ServiceSubmit;
