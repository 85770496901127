// OrderPage.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './OrderPage.css';

const OrderPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { serviceDetails, item } = location.state || {};

  if (!serviceDetails) {
    return <div>Loading...</div>;
  }

  const { name, orderPlatforms = [] } = serviceDetails;

  return (
    <div className="order-page">
      <h1>How to Order from {name}</h1>
      <p>{item?.name && `You are booking: ${item.name}`}</p>

      {orderPlatforms.length > 0 ? (
        <div className="order-methods">
          <h2>Available Platforms:</h2>
          {orderPlatforms.map((platform, index) => (
            <button
              key={index}
              className="order-button"
              onClick={() => {
                if (platform.type === 'website') {
                  window.open(platform.url, '_blank');
                } else if (platform.type === 'instagram') {
                  window.open(`https://www.instagram.com/${platform.username}`, '_blank');
                } else if (platform.type === 'whatsapp') {
                  const whatsappMessage = `Hi, I want to book ${item?.name || 'a service'} from ${name}.`;
                  window.open(`https://wa.me/${platform.username}?text=${encodeURIComponent(whatsappMessage)}`, '_blank');
                }
              }}
            >
              {`Order on ${platform.type.charAt(0).toUpperCase() + platform.type.slice(1)}`}
            </button>
          ))}
        </div>
      ) : (
        <p>This provider has not specified order platforms. Please contact them directly.</p>
      )}

      <button className="go-back-button" onClick={() => navigate(-1)}>
        Go Back
      </button>
    </div>
  );
};

export default OrderPage;
