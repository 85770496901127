// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../authService';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await login(email, password); // Use the login function from authService
      alert('Login successful!');
      navigate(-1); // Redirect to previous page after login
    } catch (error) {
      alert(error.message); // Show error message to the user
    }
  };

  return (
    <div className="login-page">
    <form className="login-form" onSubmit={handleLogin}>
      <h2>Login</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button type="submit">Login</button>
    </form>
    <p>
      Don't have an account? <a href="/signup">Sign Up</a>
    </p>
  </div>
);
};

export default Login;
