import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SubmissionSuccess.css'; // Add styles for the success page

const SubmissionSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="submission-success">
      <div className="success-icon">
        &#10004; {/* Checkmark symbol */}
      </div>
      <h1>Request Submitted Successfully</h1>
      <p>Thank you for submitting your request. We will contact you shortly.</p>
      <button onClick={() => navigate('/')} className="back-home-button">
        Go Back to Home
      </button>
    </div>
  );
};

export default SubmissionSuccess;
