import React from 'react';
import './Maintenance.css';
import { useLocation, useNavigate } from 'react-router-dom';

function Maintenance() {

  const navigate = useNavigate();
  const location = useLocation();
  const name = location.state?.name || '';
  const city = location.state?.city || 'Winnipeg'; // Winnipeg by default for now

  return (
    <div className='maintenance-page'>
      <div className='maintenance-content'>
        <h1>Under Maintenance </h1>
        <p>We are currently gathering more information.</p>
        <p>If you know about a service that should be listed here, please let us know!</p>
        <div className="buttons">
          <button onClick={() => navigate('/service-submit')}>Become a Service Provider</button>
        </div>
        <section className="provider-call">
          <h2>Do you have a service?</h2>
          <button onClick={() => navigate('/become-service-provider')}>Become a Service Provider</button>
        </section>
        <div className="navigation-buttons">
          <button onClick={() =>  navigate('/select-continent', { state: { name, city} })}>Back to Continent Select</button>
          <button onClick={() => navigate('/')}>Back to Main Page</button>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
