// AddressModal.js
import React, { useState } from 'react';
import './AddressModal.css';

const AddressModal = ({ show, handleClose, setAddress }) => {
  const citiesInCanada = [
    'Toronto', 'Vancouver', 'Montreal', 'Calgary', 'Ottawa',
    'Edmonton', 'Winnipeg', 'Quebec City', 'Hamilton', 'Kitchener',
    // Add more cities as needed
  ].sort();
  const [selectedCity, setSelectedCity] = useState('');

  const handleSave = () => {
    setAddress(selectedCity); // Update the address with the selected city
    handleClose();
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Change Address</h2>
        <label>
          Select new address:
          <select
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            placeholder="Select city"
          >
            <option value="" disabled>Select a city</option>
            {citiesInCanada.map((city, index) => (
              <option key={index} value={city}>{city}</option>
            ))}
          </select>
        </label>
        <div className="modal-actions">
          <button onClick={handleClose}>Close</button>
          <button onClick={handleSave} disabled={!selectedCity}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default AddressModal;
