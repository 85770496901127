import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // This triggers scroll to top whenever the route changes

  return null; // No UI needed for this component
};

export default ScrollToTop;
