import React, { useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom';
import ContinentPage from '../ContinentPage';
import foodImage from '../../../assets/asia/food_east.jpg';
import otherServices from '../../../assets/others.png';
import hair from '../../../assets/asia/hair_east.jpg'
import locals from '../../../assets/store.png';
// import northGarden from '../../../assets/asia/north-garden.png';
// import punjab from '../../../assets/asia/punjab.png';
// import kimchi from '../../../assets/asia/kimchi.jpg';

import { getStorage, ref, getDownloadURL } from 'firebase/storage';


const AsiaPage = () => {
  // const carouselItems = [
  //   { image: northGarden },
  //   { image: punjab },
  //   { image: kimchi },
  // ];

  const [carouselItems, setCarouselItems] = useState([]);
  const location = useLocation();
  const name = location.state?.name || '';
  const city = location.state?.city || 'Winnipeg'; // Winnipeg by default for now

  useEffect(() => {
    const storage = getStorage();
    const imagesRef = [
      'north-garden.png',
      'punjab.png',
      'kimchi.jpg',
    ];

    const fetchImages = async () => {
      try {
        const urls = await Promise.all(
          imagesRef.map((image) => {
            const imageRef = ref(storage, `${image}`);
            return getDownloadURL(imageRef);
          })
        );
        setCarouselItems(urls.map((url) => ({ image: url })));
      } catch (error) {
        console.error('Error fetching image URLs:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <ContinentPage
      continentName="Asia"
      welcomeMessage={'Welcome to Asia'}
      carouselItems={carouselItems}
      foodImage={foodImage}
      localsImage={locals}
      hairImage={hair}
      othersImage={otherServices}
      foodDescription={`Find Asian Food Services in ${city}.`}
      localBusinessesDescription="Explore Local restaurants and stores that give you a taste of home"
      otherServicesDescription="Find services like makeup, nails, fashion and others."
      hairServicesDescription="Find hairdressers that understand your goal and meet you specific needs."
      initialCity={city}
      name={name}
    />
  );
};

export default AsiaPage;
