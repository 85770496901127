import React, { useState, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase auth methods
import Slider from 'react-slick';
import './Continent.css';
import AddressModal from '../AddressModal';
import Header from '../Header';

const ContinentPage = ({
  continentName,
  welcomeMessage,
  carouselItems,
  foodImage,
  localsImage,
  hairImage,
  othersImage,
  foodDescription,
  localBusinessesDescription,
  otherServicesDescription,
  hairServicesDescription,
  initialCity,
  name,
  isLoading, // Receive loading state from parent component
}) => {

  
  const navigate = useNavigate();
  
  const [userAddress, setUserAddress] = useState(initialCity);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeCategory, setActiveCategory] = useState('All');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [userName, setUserName] = useState(name); 

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Function to check and set the user displayName if authenticated
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Set the display name if the user is logged in, otherwise keep 'User'
        console.log(user.displayName)
        setUserName(user.displayName || '');
      } else {
        setUserName('');
      }
    });

    return () => unsubscribe(); // Cleanup the listener when the component is unmounted
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log('Search query:', searchQuery);
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode', !isDarkMode);
  };



  const navigateToFoodServices = () => {
    navigate(`/food-services/${continentName.toLowerCase()}`, { state: { name: userName, city: userAddress, continent: continentName } });
  };

  const navigateToHairServices = () => {
    navigate(`/hair-services/${continentName.toLowerCase()}`, { state: { name: userName, city: userAddress, continent: continentName } });
  };

  const navigateToOtherServices = () => {
    navigate(`/other-services/${continentName.toLowerCase()}`, { state: { name: userName, city: userAddress, continent: continentName } });
  };

  const navigateToLocalBusinesses = () => {
    navigate(`/local-businesses/${continentName.toLowerCase()}`, { state: { name: userName, city: userAddress, continent: continentName } });
  };
  

  useEffect(() => {
    if (!userAddress) {
      const address = prompt('Please enter your city:');
      if (address) {
        setUserAddress(address);
      }
    }
  }, [userAddress]);

  return (
    <div className="continent-page">
      <Header
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        handleCategoryChange={handleCategoryChange}
        activeCategory={activeCategory}
        userAddress={userAddress}
        setShowModal={setShowModal}
        toggleDarkMode={toggleDarkMode}
        isDarkMode={isDarkMode}
        name={userName}
        city={userAddress}
        continentName={continentName}
      />
           <main>
        <h1 className="welcome-heading">{`${welcomeMessage} ${userName}!`} <span className="wave">👋</span></h1>

        {/* Show skeleton loader while data is loading */}
        {isLoading ? (
          <>
            <section className="carousel">
              <div className="skeleton skeleton-image"></div>
            </section>
            <section className="continent-info">
              <div className="skeleton-text"></div>
              <div className="services">
                <div className="skeleton-service-item">
                  <div className="skeleton skeleton-image"></div>
                  <div className="service-info">
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-button"></div>
                  </div>
                </div>
                {/* Add more skeleton-service-item placeholders if needed */}
              </div>
            </section>
          </>
        ) : (
          <>
            <section className="carousel">
              <Slider {...settings}>
                {carouselItems.map((item, index) => (
                  <div key={index} className="carousel-item">
                    <img className="carousel-image" src={item.image} alt={item.title} />
                  </div>
                ))}
              </Slider>
            </section>

            <section className="continent-info">
              <h2 className="intro">This part of the world is known for its culture and food rich with different flavours and varieties</h2>
              <div className="services">
                <div className="service-item">
                  <img src={foodImage} alt="Food" />
                  <div className="service-info">
                    <h3>Food</h3>
                    <p>{foodDescription}</p>
                    <button onClick={navigateToFoodServices}>Order Now</button>
                  </div>
                </div>
                <div className="service-item">
                  <img src={hairImage} alt="Hair Services" />
                  <div className="service-info">
                    <h3>Hair Services</h3>
                    <p>{hairServicesDescription}</p>
                    <button onClick={navigateToHairServices}>Book Now</button>
                  </div>
                </div>
                <div className="service-item">
                  <img src={localsImage} alt="Local Businesses" />
                  <div className="service-info">
                    <h3>Local Businesses</h3>
                    <p>{localBusinessesDescription}</p>
                    <button onClick={navigateToLocalBusinesses}>Find Local Stores</button>
                  </div>
                </div>
                <div className="service-item">
                  <img src={othersImage} alt="Other Services" />
                  <div className="service-info">
                    <h3>Other Services</h3>
                    <p>{otherServicesDescription}</p>
                    <button onClick={navigateToOtherServices}>Find Other Services</button>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>

      <AddressModal show={showModal} handleClose={() => setShowModal(false)} setAddress={setUserAddress} />
    </div>
  );
};

export default ContinentPage;
