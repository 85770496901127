import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SelectContinent.css';

function SelectContinent() {
  const location = useLocation();
  const navigate = useNavigate();
  const name = ' '+location.state?.name || '';
  const city = location.state?.city || 'Winnipeg'; // Winnipeg by default for now
  const [continent, setContinent] = useState('');
  const [welcome, setWelcome] = useState('Hello!')

  const handleSelect = (e) => {
    setContinent(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const continentPath = continent.toLowerCase().replace(/ /g, '-'); // Replace space with a -
    navigate(`/continent/${continentPath}`, { state: { name, city, continent } }); // Adjust the path and state as needed
  };


  const getWelcome = () => {
    if (name) {
      setWelcome(`Hi ${name}!`);
    }
  }
  

  return (
    <div className="select-continent-page">
      <div className="select-continent-container">
        <h2 className="select-continent-header">{welcome}</h2>
        <h2 className="select-continent-header">What part of the world makes you feel at home?</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="continent">Select a continent:</label>
          <select id="continent" value={continent} onChange={handleSelect} required>
            <option value="" disabled>Select a continent</option>
            <option value="Africa">Africa</option>
            <option value="Asia">Asia</option>
            <option value="Europe">Europe</option>
            <option value="North America">North America</option>
            <option value="South America">South America</option>
            <option value="Australia">Australia</option>
          </select>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default SelectContinent;
