// NoServices.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NoServices.css'; // Assuming you have a CSS file for styling the NoServices component

const NoServices = ({ pageTitle, city }) => {
  const navigate = useNavigate();

  const handleSubmitService = () => {
    navigate('/service-submit'); 
  };

  const handleBecomeProvider = () => {
    navigate('/become-service-provider')
  };


  return (
    <div className='no-services-page'>
      <div className='no-services-content'>
        <h1>No {pageTitle} Available</h1>
        <p>We are currently gathering more information on {pageTitle} in {city}.</p>
        <p>If you know about a service that should be listed here, please let us know!</p>
        <div className="buttons">
          <button onClick={handleSubmitService}>Submit a Service</button>
        </div>
        <section className="provider-call">
          <h2>Do you have a service?</h2>
          <button onClick={handleBecomeProvider}>Become a Service Provider</button>
        </section>
      </div>
    </div>
  );
};

export default NoServices;
