import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './EnterLocationPage.css';

function EnterLocationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const name = ' '+location.state?.name || '';
  const [country, setCountry] = useState('Canada'); // Default to Canada
  const [city, setCity] = useState('');

  // List of cities in Canada, sorted alphabetically
  const citiesInCanada = [
    'Toronto', 'Vancouver', 'Montreal', 'Calgary', 'Ottawa',
    'Edmonton', 'Winnipeg', 'Quebec City', 'Hamilton', 'Kitchener',
    // Add more cities as needed
  ].sort();

  const handleSubmit = () => {
    if (!city) {
      alert("City set to Winnipeg by default");
      setCity('Winnipeg'); // Set default city
    }
    navigate('/select-continent', { state: { city, name } }); // Pass city to the next page
  };

  return (
    <div className="enter-location-page">
      <div className="form-container">
        {/* Country Selector */}
        <label htmlFor="country">Please select your country:</label>
        <select
          id="country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          disabled // Disabled because only Canada is available now
        >
          <option value="Canada">Canada</option>
        </select>

        {/* City Selector */}
        <label htmlFor="city">Please select your city:</label>
        <select
          id="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        >
          <option value="" disabled>Select your city</option>
          {citiesInCanada.map(city => (
            <option key={city} value={city}>{city}</option>
          ))}
        </select>
        
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}

export default EnterLocationPage;
