// CartPage.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './CartPage.css';  // Import the CSS file


const CartPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { cart, quantities, serviceDetails } = location.state || {};

    const handleOrderClick = (platform, message) => {
        if (platform.type === 'website') {
          window.open(`${platform.url}`, '_blank');
        } else if (platform.type === 'whatsapp') {
          const whatsappMessage = encodeURIComponent(message);
          window.open(`https://wa.me/${platform.username}?text=${whatsappMessage}`, '_blank');
        } else {
          if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(message)
              .then(() => {
                if (platform.type === 'snapchat') {
                  window.open(`https://www.snapchat.com/add/${platform.username}`, '_blank');
                } else if (platform.type === 'instagram') {
                  window.open(`https://www.instagram.com/${platform.username}`, '_blank');
                }
                alert('Message copied to clipboard! Now paste it to order.');
              })
              .catch(err => {
                console.error('Failed to copy: ', err);
              });
          } else {
            alert(`Copy the following message manually: ${message}`);
            if (platform.type === 'snapchat') {
              window.open(`https://www.snapchat.com/add/${platform.username}`, '_blank');
            } else if (platform.type === 'instagram') {
              window.open(`https://www.instagram.com/${platform.username}`, '_blank');
            } else if (platform.type === 'whatsapp') {
              const whatsappMessage = encodeURIComponent(message);
              window.open(`https://wa.me/${platform.username}?text=${whatsappMessage}`, '_blank');
            }
          }
        }
      };

  const renderOrderButtons = (item) => {
    return serviceDetails.orderPlatforms.map((platform, index) => (
      <button
        key={index}
        onClick={() =>
          handleOrderClick(
            platform,
            `Hi, I saw your service on Servillia and would like to order ${item.name} (Quantity: ${quantities[item.name]})`
          )
        }
      >
        Order via {platform.type.charAt(0).toUpperCase() + platform.type.slice(1)}
      </button>
    ));
  };

  return (
    <div className="cart-page">
      <h1>Your Cart</h1>
      {cart.map((item, index) => (
        <div key={index} className="cart-item">
          <span>{item.name}</span>
          <span>Quantity: {quantities[item.name]}</span>
          {renderOrderButtons(item)}
        </div>
      ))}
      <button onClick={() => navigate(-1)}>Go Back</button>
    </div>
  );
};

export default CartPage;
