import React, { useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom';
import ContinentPage from '../ContinentPage';
// import afv from '../../../assets/africa/afv.png';
// import auntyShawarma from '../../../assets/africa/aunty-shawarma.png';
// import bura from '../../../assets/africa/bura.jpg';
// import mySpiceHouse from '../../../assets/africa/my-spice-house.png';
import foodImage from '../../../assets/africa/food.jpg';
import locals from '../../../assets/store.png';
import hair from '../../../assets/africa/hair.jpg'
import otherServices from '../../../assets/africa/services.jpg';

import { getStorage, ref, getDownloadURL } from 'firebase/storage';


const AfricaPage = () => {
  // const carouselItems = [
  //   { image: afv },
  //   { image: auntyShawarma },
  //   { image: bura },
  //   { image: mySpiceHouse },
  // ];

  const [carouselItems, setCarouselItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const name = location.state?.name || '';
  const city = location.state?.city || 'Winnipeg'; // Winnipeg by default for now

  useEffect(() => {
    const storage = getStorage();
    const imagesRef = [
      'afv.png',
      'my-spice-house.png',
      'aunty-shawarma.png',
      'royal-touch.jpg',
      'bura.jpg',
    ];

    const fetchImages = async () => {
      try {
        const urls = await Promise.all(
          imagesRef.map((image) => {
            const imageRef = ref(storage, `${image}`);
            return getDownloadURL(imageRef);
          })
        );
        setCarouselItems(urls.map((url) => ({ image: url })));
        setLoading(false); // Set loading to false after images are fetched
      } catch (error) {
        console.error('Error fetching image URLs:', error);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  return (
    <ContinentPage
      continentName="Africa"
      welcomeMessage={'Welcome to Africa'}
      carouselItems={carouselItems}
      foodImage={foodImage}
      localsImage={locals}
      hairImage={hair}
      othersImage={otherServices}
      foodDescription="From the simplicity of a roadside suya purcase to the quality of a spicy shawarma. Find it all here."
      localBusinessesDescription={`Find African local businesses in ${city}.`}
      otherServicesDescription={`Find Other services in ${city}.`}
      hairServicesDescription="Explore and select from the best barbers and hairdressers in your city."
      initialCity={city}
      name={name}
      isLoading={loading} // Pass the loading state as a prop to ContinentPage
    />
  );
};

export default AfricaPage;
