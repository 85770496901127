import React, { useState, useEffect } from 'react';
import Header from '../Header'; // Assuming Header is in the same folder level
import AddressModal from '../AddressModal'; // Import AddressModal if needed
import './ServicePage.css'; // Assuming you have a CSS file for styling the service page
import NoServices from './NoServices';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as filledBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as emptyBookmark } from '@fortawesome/free-regular-svg-icons';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase'; 
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc, setDoc } from 'firebase/firestore'; // Firestore methods

const ServicePage = ({
  pageTitle,
  serviceItems,
  initialCity,
  userName,
  continent,
}) => {
  const [userAddress, setUserAddress] = useState(initialCity);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeCategory, setActiveCategory] = useState('All');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [favorites, setFavorites] = useState([])
  const auth = getAuth();
  const navigate = useNavigate();


    // Function to fetch the user's favorite services from Firestore
    const fetchFavorites = async (userId) => {
      const userRef = doc(db, 'users', userId); // Reference to the user's document in 'users' collection
      const docSnap = await getDoc(userRef);
    
      if (docSnap.exists()) {
        setFavorites(docSnap.data().favorites || []); // Fetch 'favorites' from user document
      } else {
        await setDoc(userRef, { favorites: [] });
        setFavorites([]);
      }
    };

    // UseEffect to fetch the user's favorites from Firestore once the user is authenticated
    useEffect(() => {
      const user = auth.currentUser;
      if (user) {
        fetchFavorites(user.uid);
      }
    }, [auth.currentUser]);



  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log('Search query:', searchQuery);
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

    // Function to determine button text based on service category
    const getButtonText = (category) => {
      switch (category.toLowerCase()) {
        case 'food':
          return 'Order Now';
        case 'hair':
          return 'Book Now';
        case 'other':
          return 'Request Service';
        case 'local':
          return 'Visit';
        default:
          return 'Learn More';
      }
    };

    const navigateToServiceDetail = (serviceId) => {
      navigate(`/service/${serviceId}`, { state: { name: userName } });
    };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode', !isDarkMode);
  };

  const handleFavoriteClick = async (service) => {
    const user = auth.currentUser;
    if (!user) {
      alert('You must be logged in to add to favorites.');
      return;
    }
  
    const userRef = doc(db, 'users', user.uid); // Get the reference to the user's document in the 'users' collection
  
    try {
      if (favorites.includes(service.id)) {
        // If the service is already in the user's favorites, remove it
        await updateDoc(userRef, {
          favorites: arrayRemove(service.id), // Remove the service ID from the 'favorites' array
        });
        setFavorites(favorites.filter((favId) => favId !== service.id));
        alert(`${service.name} removed from favorites.`);
      } else {
        // If the service is not in the user's favorites, add it
        await updateDoc(userRef, {
          favorites: arrayUnion(service.id), // Add the service ID to the 'favorites' array
       
 });
        setFavorites([...favorites, service.id]);
        alert(`${service.name} added to favorites.`);
      }
    } catch (error) {
      console.error("Error updating favorites: ", error);
      alert('An error occurred while updating your favorites.');
    }
  };

  const isFavorite = (serviceId) => favorites.includes(serviceId);

  return (
    <div className="service-page">
      <Header
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        handleCategoryChange={handleCategoryChange}
        activeCategory={activeCategory}
        userAddress={userAddress}
        setShowModal={setShowModal}
        toggleDarkMode={toggleDarkMode}
        isDarkMode={isDarkMode}
        logoClickHandler={() => console.log('Logo clicked!')}
        name={userName}
        city={userAddress}
      />

    {serviceItems.length > 0 ? (
        <main>
          <h1 className="service-page-title">{pageTitle} in {userAddress}</h1>
          <div className="services-list">
            {serviceItems.map((service, index) => (
              <div key={index} className="service-item">
                <div className="service-actions">
                <div className="service-actions">
                  <FontAwesomeIcon
                      icon={isFavorite(service.id) ? filledBookmark : emptyBookmark}
                      className="favorite-icon"
                      onClick={() => handleFavoriteClick(service)}
                  />
                </div>
                </div>
                <img src={service.imageUrl} alt={service.title} />
                <h3>{service.name}</h3>
                <p>{service.description}</p>
                <button onClick={() => navigateToServiceDetail(service.id)}>{getButtonText(service.category)}</button>
              </div>
            ))}
          </div>
        </main>
      ) : (
        <NoServices 
        pageTitle={pageTitle}
        city={initialCity} />
      )}

      <AddressModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        setAddress={setUserAddress}
      />
    </div>
  );
};

export default ServicePage;
